export default {
  name: 'v-layout-header',
  data() {
    return {
      dialogs: {
        logout: false,
      },
      drawers: {
        menu: false,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleLogoutClick() {
      this.dialogs.logout = true
    },

    /**
     * 
     */
    async handleLogoutAcceptClick() {

      // reset auth module
      await this.$store.dispatch('AuthModule/reset')

      // reset provider module
      await this.$store.dispatch('ProviderModule/reset')

      // reset user module
      await this.$store.dispatch('UserModule/reset')

      // close dialog
      this.dialogs.logout = false

      // redirect
      this.$router.push('/')
    },

    /**
     * 
     */
    handleLogoutCancelClick() {
      this.dialogs.logout = false
    },
  }
}